import React from 'react'
import Layout from '../components/layout'

function SubprocesssorsList () {
  return (
    <Layout>
      <div className="w-full max-w-[800px] mx-auto mt-[144px] lg:mt-[240px] pb-32">
        <div className="px-4 lg:px-0">
          <div className="text-center">
            <div className="">LegalEye</div>
            <h1 className="text-3xl mt-6 md:text-6xl  md:leading-tight font-GTWalsheimPro font-medium">
              Sub Processors
            </h1>
          </div>
          <p className="mt-8 text-[#6a6a6a]">
            To support delivery of our Services, LegalEye may transfer data to
            its Sub-Processors.
            <br />
            <br />
            We use the third parties listed below to host our customer data and
            provide specific functionality within the service
          </p>
          <table className="w-full">
            <tr className="border-b">
              <th className="py-4 pr-4 text-left">Sub-Processor Name</th>
              <th className="p-4 text-left">Type of Service</th>
              <th className="p-4 text-left">Data Location and Security</th>
            </tr>
            <tr className="border-b">
              <td className="py-4 pr-4">Amazon Web Services, Inc.</td>
              <td className="p-4">Cloud hosting and storage</td>
              <td className="p-4">Customisable for Tenant Owner</td>
            </tr>
            <tr className="border-b">
              <td className="py-4 pr-4">SendGrid Inc.</td>
              <td className="p-4">Email notification service</td>
              <td className="p-4">USA</td>
            </tr>
            <tr className="border-b">
              <td className="py-4 pr-4">Intercom</td>
              <td className="p-4">Support ticketing service</td>
              <td className="p-4">USA</td>
            </tr>
            <tr className="border-b">
              <td className="py-4 pr-4">Sentry</td>
              <td className="p-4">Error Monitoring</td>
              <td className="p-4">USA</td>
            </tr>
            <tr className="border-b">
              <td className="py-4 pr-4">Auth0</td>
              <td className="p-4">Identity and Authorisation</td>
              <td className="p-4">Customisable for Tenant Owner</td>
            </tr>
          </table>
          <p className="mt-8 text-[#6a6a6a]">
            The list of Sub-Processors we engage may change from time to time.
            Please check back frequently for updates.
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default SubprocesssorsList
